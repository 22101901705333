body, html{
  height: 100%;
  width: 100%;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden !important;
  font-family: 'Roboto',Arial, sans-serif;
  /* background-color: #000;
  color: #fff; */
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a, a:visited, a:focus{
  outline: none;
  border: none;
  /* color: #fff; */
  text-decoration: none;
}
a:hover{
  text-decoration: underline;
}
.MuiPaper-root.MuiPaper-elevation {
  min-height: 140px;
  padding: 10px;
  /* text-align: center; */
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
  transform: translate(13px, 9px) scale(1) !important;
}
.mobile-menu-wrapper{
  display: none;
}
.btn-export-report{
  background-color: rgb(25, 118, 210);
  color: rgb(255, 255, 255);
  text-decoration: none;
  height: 35px;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  border-radius: 5px;
  padding: 1px 10px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
}
.btn-export-report:hover{
  text-decoration: none;
  background-color: #1565c0;
}
.btn-export-report.warning {
  background-color: #a56d00 !important;
}
.btn-export-report.warning:hover {
  background-color: #624100 !important;
}
.btn-loading-filter{
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  background-color: #ff00eb;
  color: #fff;
  border-radius: 5px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.brand-color-1{
  background-color: #00ff00 !important; 
}
.brand-color-2{
  background-color: #0000ff !important;
  color: #ffffff !important;
}
.brand-color-3{
  background-color: #000000 !important;
  color: #ffffff !important;
}
.brand-color-4{
  background-color: #ff0000 !important; 
}
.brand-color-5{
  background-color: #8800ff !important;
  color: #ffffff !important;
}
.brand-color-6{
  background-color: #ffff00 !important;
  color: #000000 !important;
}
.brand-color-7{
  background-color: #ff00f0 !important;
  color: #ffffff !important;
}
.brand-color-8{
  background-color: #5d5d5d !important;
  color: #ffffff !important;
}
.text-error-info{
  text-align: center;
  color: #ff0000;
  padding: 10px;
  display: block;
}
.main-header{
  height: 50px;
  background-color: #d2d2d2;
  display: table;
  width: 100%;
}
.main-header a {
  color: rgb(45, 45, 45);
}
.main-header .logo-wrapper, .main-header .nav-link, .main-footer .logo-wrapper, .main-footer .nav-link{
  display: table-cell;
  vertical-align: middle;
}
.main-header .logo-wrapper{
  width: 150px;
  text-align: center;
  font-size: 20px;
  font-weight: 800;
}
.main-header .nav-link{
  text-align: right;
}
.nav-link ul {
  margin: 0px;
}
.main-header li, .main-footer li{
  display: inline-block;
  min-width: 100px;
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
}
.body{
  min-height: calc(100vh - 90px);
}
.body-container {
  position: relative;
  display: block;
  background-color: #dedede;
}
.main-container{
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
.main-container-dashboard {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  align-items: stretch;
}
.main-side-menu{
  height: calc(100vh - 90px);
  /* width: 240px; */
}
.dashboard-left-panel{
  width: 240px;
  background-color: #c5c5c5;
}
.dashboard-left-panel ul {
  padding: 0px;
  margin: 0;
  list-style: none;
}
.dashboard-left-panel ul li a {
  padding: 10px;
  display: block;
  background-color: #b2b2b2;
  margin-bottom: 1px;
  color: #000000;
}
.dashboard-right-panel{
  width: calc(100% - 240px);
  padding: 10px;
}
.login-page {
  display: flex;
  height: 500px;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
}
.login-container {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 5px 5px 3px #1a1a1a36;
  background-color: #f6f6f6;
  border: 1px solid #d9d9d9;
}
.login-title {
  text-align: center;
  display: block;
  text-transform: uppercase;
  font-size: 16px;
}
.main-footer{
  text-align: center;
  font-size: 12px;
  background-color: #171717;
  color: #fff;
  height: 40px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.main-footer p{
  font-size: 13px;
}
.canvas-container {
  background-color: #f6f6f6;
  margin-left: auto;
  margin-right: auto;
}
.field-time{
  display: block;
  text-align: center;
}
.required {
  color: rgb(192, 14, 14);
}